<template>
  <div class="loginPage">
    <div class="login">
      <div class="loginContainer">
        <div class="logo">
          <img src="@/assets/img/login/logo.jpg" alt="">
        </div>
        <div class="loginBox">
          <p class="loginText">로그인</p>
          <input type="text" placeholder="아이디를 입력해주세요." v-model="id">
          <input type="password" placeholder="비밀번호를 입력해주세요." v-model="password">
          <div :class="{ 'error': isError }" v-if="isError">
            아이디와 비밀번호를 확인해주세요
          </div>
        </div>

        <button
          ref="loginBtn"
          class="loginBtn"
          @click="loginOK"
        >
          로그인
        </button>

      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "login",
  data() {
    return {
      id: '',
      password: '',
      isError: false,
    }
  },
  computed: {
    loginParam() {
      return {
        id: this.id,
        password: this.password
      }
    }
  },
  watch: {
    id(value) {
      if (value === '' || value === null) {
        this.$refs.loginBtn.classList.remove('clickAble')
      } else {
        this.$refs.loginBtn.classList.add('clickAble')
      }
    },
    password(value) {
      if (value === '' || value === null) {
        this.$refs.loginBtn.classList.remove('clickAble')
      } else {
        this.$refs.loginBtn.classList.add('clickAble')
      }
    }
  },
  methods: {
    async loginOK () {
      await this.$Connect('/v1/login', 'post', this.loginParam).then(res => {
        this.$sessionStorageUtils.save('token', res.token)
        this.$router.push({ path: '/inheritForm' })
      }).catch(error => {
        if (error.request.status === 401 || error.request.status === 403 || error.request.status === 422) {
          this.isError = true
        } else {
          alert('알 수 없는 오류가 발생했습니다. 관리자에 문의하세요.')
          console.error(error)
        }
      })
    },

  }
}
</script>
